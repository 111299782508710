<template>
  <b-modal
    ref="delete-member-modal"
    :title="member != null ? `Supprimer ${member.first_name} ${member.last_name}` : ''"
    hide-footer
  >
    <validation-observer ref="simpleRules">
      <b-form v-if="member">
        <b-row>
          <b-col class="pb-2" md="12">
            Êtes-vous sûr de vouloir supprimer ce conseiller ?
            </b-col>
          <b-col v-if="member.has_reservation" md="12">
            <b-form-group
              :label="t(`agency.choose_agent_label`)"
              label-for="reassigned_user_id"
            >
              <v-select
                id="reassigned_user_id"
                ref="reassigned_user_id"
                v-model="reassignedMember"
                :options="members"
                label="text"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <div class="d-flex justify-content-end">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-secondary"
        size="md"
        :disabled="loading"
        @click="closeModal"
        class="mr-1"
      >
        Annuler
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        :disabled="loading"
        @click="submit(member.id)"
        size="md"
      >
        Supprimer
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
  BCardText,
  BRow,
  BCol,
  BInputGroup,
  BFormCheckbox,
  BInputGroupPrepend,
  BFormRadio,
  BFormTextarea,
  BTabs,
  BFormFile,
  BFormCheckboxGroup,
  BTab,
  BFormInvalidFeedback,
  BFormRadioGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useLocalisation } from "@/shared/composables/use-localisation";
import { useAgencyApi } from "@/modules/agency/composables/use-agency-api";
import { onMounted, ref, computed, reactive } from "@vue/composition-api";
import { useUserApi } from "@/views/administrators/composables/use-user-api";
import { useToastNotification } from "@/shared/composables/use-toast-notifications";
import { useApplicationContext } from "@/shared/composables/use-application-context";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "DeleteMemberModal",
  components: {
    BCardCode,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BCardText,
    BFormGroup,
    vSelect,
    BRow,
    BFormRadioGroup,
    BCol,
    BInputGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BTabs,
    BFormFile,
    BTab,
    BFormInvalidFeedback,
    ToastificationContent,
    BFormRadio,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    member: {
      type: Object | null,
      default: null,
    },
    currentAgencyId: {
      type: String,
      required: true,
    },
    agencyMembers: {
      type: Object | Array,
      default: function () {
        return [];
      },
    },
  },

  setup(props, { emit }) {
    const { refOf } = useApplicationContext();
    const { fetchMembersOfAgency, deleteUser } = useUserApi();
    const { displaySuccessMessage, displayErrorMessage } =
      useToastNotification();
    const { t } = useLocalisation();
    const agencies = ref([]);
    const currentAgencyId = props.currentAgencyId;

    let loading = ref(false);

    let form = reactive({
      reassigned_user_id: "",
    });

    const fetchAgencies = async () => {
      let response = await useAgencyApi().listAgencies();
      agencies.value = [...new Set(response)]
        .filter((x) => x.id !== currentAgencyId)
        .map((x) => {
          return { text: x.name, value: x.id };
        });
    };

    const submit = async (userId) => {
      refOf("simpleRules")
        .validate()
        .then((success) => {
          if (success) {
            deleteMember(userId);
          }
        });
    };

    const deleteMember = async (userId) => {
      try {
        loading.value = true;
        await deleteUser(userId, form);
        displaySuccessMessage(
          "Les changements ont été sauvegardés avec succès"
        );
        form.reassigned_user_id = "";
        refOf("delete-member-modal").hide();
        emit("refresh-members");
        loading.value = false;
      } catch (e) {
        console.error(e);
        displayErrorMessage(
          "Une erreur est survenue en essayant de sauvegarder les changements"
        );
        loading.value = true;
      }
    };

    onMounted(async () => {
      await fetchAgencies();
    });

    return {
      t,
      form,
      agencies,
      loading,
      deleteMember,
      submit,
    };
  },
  computed: {

    members() {
      const filtered = this.agencyMembers.filter((x)=> x.id !== this.member.id)
      return [...new Set(filtered)].map((x) => {
        return { text: x.full_name, value: x.id };
      });
    },
    reassignedMember: {
      get() {
        if (this.form.reassigned_user_id) {
          return (
            this.members.find(
              (x) => x.value === this.form.reassigned_user_id
            ) ?? null
          );
        }
        return "";
      },
      set(member) {
        this.form.reassigned_user_id = member ? member.value : "";
        return member;
      },
    },
  },
  methods: {
    resetForm() {
      this.form.reassigned_user_id = "";
    },
    closeModal() {
      this.resetForm();
      this.$refs["delete-member-modal"].hide();
    },
  },
};
</script>

<style scoped></style>
