<template>
  <b-sidebar
    id="add-new-agency-sidebar"
    :visible="isAddNewMemberSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-member-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Ajouter un nouveau conseiller
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(addMemberToAgency)"
          @reset.prevent="resetForm"
        >

          <h5 class='mt-2 mb-1'> Information </h5>

          <validation-provider
            #default="validationContext"
            name="Nom"
            rules="required"
          >
            <b-form-group
              label="Prénom"
              label-for="main-agency-admin-first-name"
            >
              <b-form-input
                id="main-agency-admin-first-name"
                v-model="memberData.agentFirstName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="John"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

                    <validation-provider
            #default="validationContext"
            name="Nom"
            rules="required"
          >
            <b-form-group
              label="Nom"
              label-for="main-agency-admin-last-name"
            >
              <b-form-input
                id="main-agency-admin-last-name"
                v-model="memberData.agentLastName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Snow"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group
              label="Courriel"
              label-for="main-agency-admin-email"
            >
              <b-form-input
                id="main-agency-admin-email"
                v-model="memberData.agentEmail"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- User Role -->
          <validation-provider
            #default="validationContext"
            name="User Role"
            rules="required"
          >
            <b-form-group
              label="Rôle"
              label-for="user-role"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="memberData.is_admin"
                :options="roleOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-role"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <div v-if="formIsSubmittable">Ajouter</div>
              <div v-if="!formIsSubmittable">
              <b-spinner small type="grow"></b-spinner>
                Chargement...
              </div>

            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Annuler
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BSpinner
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '../../../tests/mocks/countries'
import store from '@/store'
import router from '@/router'
import {useUserApi} from '@/views/administrators/composables/use-user-api';
import {useToastNotification} from "@/shared/composables/use-toast-notifications";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BSpinner,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewMemberSidebarActive',
    event: 'update:is-add-new-member-sidebar-active',
  },
  props: {
    isAddNewMemberSidebarActive: {
      type: Boolean,
      required: true,
    },
    currentAgencyId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries
    }
  },
  setup(props, { emit }) {
    const {addUserToAgency} = useUserApi();
    const {displaySuccessMessage, displayErrorMessage} = useToastNotification();

    const roleOptions = [
      { label: 'Administrateur', value: 'true' },
      { label: 'Conseiller', value: 'false' },
    ]

    const blankAgentData = {
      is_admin: '',
      agentFirstName: '',
      agentLastName: '',
      agentEmail: '',
      agency_id: props.currentAgencyId
    }

    const formIsSubmittable = ref(true);

    const memberData = ref(JSON.parse(JSON.stringify(blankAgentData)))

    const resetmemberData = () => {
      memberData.value = JSON.parse(JSON.stringify(blankAgentData))
    }

    const addMemberToAgency = async () => {

       try {
        formIsSubmittable.value = false;

        await addUserToAgency(memberData.value)
        formIsSubmittable.value = true;
        emit('refetch-data');
        emit('update:is-add-new-member-sidebar-active', false);

        displaySuccessMessage('Les changements ont été sauvegardés avec succès');

      } catch (e) {
        emit('update:is-add-new-member-sidebar-active', false);
        displayErrorMessage('Une erreur est survenue en essayant de sauvegarder les changements');
        formIsSubmittable.value = true;

      }

    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetmemberData)

    return {
      memberData,
      addMemberToAgency,
      roleOptions,
      refFormObserver,
      getValidationState,
      resetForm,
      formIsSubmittable
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
