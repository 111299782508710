<template>
  <div>
    <agency-add-member
      v-if="currentAgencyId"
      :is-add-new-member-sidebar-active.sync="isAddNewMemberSidebarActive"
      :current-agency-id="currentAgencyId"
      @refetch-data="fetchAgencyMembers"
    />
    <!-- search input -->

    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <b-form-input
            v-model="searchTerm"
            placeholder="Recherche"
            type="text"
            class="d-inline-block"
          />
          <b-button
            variant="primary"
            @click="isAddNewMemberSidebarActive = true"
            class="ml-1"
          >
            <span class="text-nowrap">Ajouter un conseiller</span>
          </b-button>
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="agencyMembers"
      styleClass="vgt-table striped"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <span v-if="props.column.field === 'full_name'" class="text-nowrap">
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
            :variant="avatarColors(flattenUUIDtoBase(props.row.id))"
          />
          <span class="text-nowrap">{{ props.row.full_name }}</span>
        </span>

        <!-- Column: type -->
        <span v-else-if="props.column.field === 'role'">
          <b-badge :variant="roleVariant(props.row.role)">
            {{ roleLabel(props.row.role) }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>

              <b-dropdown-item
                :to="{ name: 'member-edit', params: { id: props.row.id } }"
                v-if="
                  currentAuthUser.role.role == 'agency_admin' ||
                  currentAuthUser.role.role == 'organization_admin'
                "
              >
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>Modifier</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="
                  currentAuthUser.role.role == 'organization_admin'
                "
                @click="showAgencyMemberSwitchModal(props.row.id)"
              >
                <feather-icon icon="RepeatIcon" class="mr-50" />
                <span>Changer d'agence</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="
                  currentAuthUser.role.role == 'organization_admin'
                "
                @click="showDeleteMemberModal(props.row.id)"
              >
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>Supprimer</span>
              </b-dropdown-item>
              <!--
              <b-dropdown-item>
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Supprimer</span>
              </b-dropdown-item>
              -->
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Indiquer 1 à </span>
            <b-form-select
              v-model="pageLength"
              :options="['5', '10', '20']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> des {{ props.total }} données </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <switch-agency-modal
      v-if="currentAgencyId"
      ref="switchAgencyModal"
      :member="selectedMember"
      :current-agency-id="currentAgencyId"
      :agency-members="agencyMembers"
      @refresh-members="fetchAgencyMembers"
    />
    <delete-member-modal
      v-if="currentAgencyId"
      ref="deleteMemberModal"
      :member="memberToDelete"
      :current-agency-id="currentAgencyId"
      :agency-members="agencyMembers"
      @refresh-members="fetchAgencyMembers"
    />
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BAvatar,
  BBadge,
  BPagination,
  BFormSelect,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref, nextTick, onMounted } from "@vue/composition-api";
import { VueGoodTable } from "vue-good-table";
import AgencyAddMember from "./AgencyAddMember";
import { useUserApi } from "@/views/administrators/composables/use-user-api";
import { useToastNotification } from "@/shared/composables/use-toast-notifications";
import { useApplicationContext } from "@/shared/composables/use-application-context";
import {
  AUTH_STORE_NAMESPACE,
  AUTHENTICATED_USER_GETTER,
} from "@/modules/authnz/models/store";
import SwitchAgencyModal from "../components/SwitchAgencyModal.vue";
import DeleteMemberModal from "../components/DeleteMemberModal.vue";

export default {
  components: {
    BButton,
    BMedia,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormSelect,
    BDropdown,
    AgencyAddMember,
    BDropdownItem,
    SwitchAgencyModal,
    DeleteMemberModal
  },
  props: {
    agencyData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { fetchMembersOfAgency } = useUserApi();
    const { displayErrorMessage } = useToastNotification();
    const { route, store, refOf } = useApplicationContext();

    let isAddNewMemberSidebarActive = false;
    const currentAuthUser = ref({});
    const currentAgencyId = ref(null);
    const agencyMembers = ref([]);
    const selectedMember = ref(null);
    const memberToDelete = ref(null);

    const fetchAgencyMembers = async () => {
      try {
        currentAuthUser.value =
          store.getters[`${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`];

        let response = await fetchMembersOfAgency(currentAgencyId.value);
        agencyMembers.value = response.data;
      } catch (e) {
        displayErrorMessage(
          "Une erreur est survenue en essayant de charger les conseillers de l'agence"
        );
      }
    };

    onMounted(async () => {
      try {
        if (route.value.params.agency_id) {
          currentAgencyId.value = route.value.params.agency_id;
        } else {
          currentAuthUser.value =
            store.getters[
              `${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`
            ];
          currentAgencyId.value = currentAuthUser.value.agency_id;
        }
        fetchAgencyMembers();
      } catch (e) {
        console.error(e);
      }
    });

    const showAgencyMemberSwitchModal = (userId) => {
      selectedMember.value = agencyMembers.value.find((x) => x.id === userId);
      nextTick(() => {
        refOf("switchAgencyModal").$children[0].show();
      });
    };

    const showDeleteMemberModal = (userId) => {
      memberToDelete.value = agencyMembers.value.find((x) => x.id === userId);
      nextTick(() => {
        refOf("deleteMemberModal").$children[0].show();
      });
    };

    return {
      isAddNewMemberSidebarActive,
      agencyMembers,
      fetchAgencyMembers,
      currentAuthUser,
      showAgencyMemberSwitchModal,
      selectedMember,
      currentAgencyId,
      showDeleteMemberModal,
      memberToDelete
    };
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      columns: [
        {
          label: 'Nom',
          field: 'full_name',
        },
        {
          label: 'Courriel',
          field: 'email',
        },
        {
          label: 'Rôle',
          field: 'role',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      role: [{
        1: 'Coordinator',
        2: 'Administrator',
      },
      {
        1: 'light-primary',
        2: 'light-success',
      }],
    }
  },
  computed: {

    roleVariant() {
      const roleColor = {
        agency_admin  : 'light-warning',
        agency_agent  : 'light-info',
      }
      return role => roleColor[role]
    },

    roleLabel() {
      const roleLabel = {
        agency_admin  : "Administrateur",
        agency_agent  : 'Agent',
      }
      return role => roleLabel[role]
    },
    flattenUUIDtoBase() {
      const UUID_BASE = 16;
      const BASE = 2;
      return character => Math.round(parseInt(character.slice(-1),UUID_BASE)/BASE)
    }
    ,
    avatarColors() {

      const avatarColors = {
        "1":"primary",
        "2":"dark",
        "3":"info",
        "4":"success",
        "5":"danger",
        "6":"warning",
        "7":"info",
        "8":"secondary",
      }
      return character => avatarColors[character]
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
